import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import {
  TwoBtnModalComponent,
  TwoBtnModalState
} from './two-btn-modal/two-btn-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private modalService: NgbModal) {}

  showTwoBtnModal(options: TwoBtnModalState): Observable<boolean> {
    const modalRef = this.modalService.open(TwoBtnModalComponent, options.preventClose ? {
      backdrop : 'static',
      keyboard : false
    } : {});

    modalRef.componentInstance.title = options.title;
    modalRef.componentInstance.message = options.message;
    modalRef.componentInstance.confirmBtnText = options.confirmBtnText
    modalRef.componentInstance.confirmBtnClass = options.confirmBtnClass;
    modalRef.componentInstance.cancelBtnText = options.cancelBtnText;
    modalRef.componentInstance.cancelBtnClass = options.cancelBtnClass;
    modalRef.componentInstance.confirm$ = options.confirm$;

    return modalRef.componentInstance.modalResult;
  }
}