/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeviceConfigurationDtoNBK } from './deviceConfigurationDto';

export interface DeviceDtoNBK {
  id?: number;
  name?: string;
  hardwareId?: string;
  firmware?: string;
  serial?: string;
  online?: boolean;
  model?: DeviceDtoNBK.ModelEnum;
  owner?: string;
  ownerUsername?: string;
  registrationDate?: string;
  productionDate?: string;
  lastSync?: string;
  lastInfoUpdate?: string;
  currentOwnerFirstAssociation?: string;
  deviceConfiguration?: DeviceConfigurationDtoNBK;
}
export namespace DeviceDtoNBK {
  export type ModelEnum = 'ORACLE';
  export const ModelEnum = {
    Oracle: 'ORACLE' as ModelEnum
  };
}
