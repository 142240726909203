<div class="modal-header">
  <h2 *ngIf="!canChangeName">{{ folder.name }}</h2>
  <input
    class="custom-input"
    *ngIf="canChangeName"
    [formControl]="newFolderName"
  />
  <button
    class="button-primary name-button"
    (click)="toggleChangeName()"
    *ngIf="!canChangeName"
  >
    {{ 'MY_DISPLAY.CHANGE_NAME' | translate }}
  </button>
  <button
    class="button-primary name-button"
    (click)="changeName()"
    *ngIf="canChangeName"
  >
    {{ 'MY_DISPLAY.SAVE_NEW_NAME' | translate }}
  </button>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  <div class="d-flex flex-wrap">
    <app-recipe-box
      *ngFor="let r of recipesInFolder"
      [recipe]="r!"
      class="m-3"
      [canDelete]="true"
      (onDelete)="onDeleteRecipe($event)"
    ></app-recipe-box>
  </div>
</div>
<!--<div class="modal-footer">
   <button
      class="button-primary"
      (click)="add()"
    >
      {{ 'GLOBAL.ADD' | translate }}
    </button> 
</div>-->
