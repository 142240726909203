/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PhaseActionBoDtoNBK } from './phaseActionBoDto';
import { AttributeDtoNBK } from './attributeDto';

export interface PhaseBoDtoNBK {
  id?: number;
  cookingType?: PhaseBoDtoNBK.CookingTypeEnum;
  magnetron?: number;
  fanSpeed?: number;
  temperature?: number;
  time?: number;
  alternatingFan?: boolean;
  aroma?: number;
  attributes?: AttributeDtoNBK;
  deltaT?: boolean;
  smokegrill?: number;
  ventPreopenTimer?: number;
  ventOpen?: boolean;
  actions?: Array<PhaseActionBoDtoNBK>;
}
export namespace PhaseBoDtoNBK {
  export type CookingTypeEnum = 'CONVECTION' | 'STEAM' | 'COMBO' | 'MICROWAVE';
  export const CookingTypeEnum = {
    Convection: 'CONVECTION' as CookingTypeEnum,
    Steam: 'STEAM' as CookingTypeEnum,
    Combo: 'COMBO' as CookingTypeEnum,
    Microwave: 'MICROWAVE' as CookingTypeEnum
  };
}
