import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromUTCToLocale'
})
export class FromUTCToLocalePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): Date {
    let datestring = new Date(value);
    return new Date(
      Date.UTC(
        datestring.getFullYear(),
        datestring.getMonth(),
        datestring.getDate(),
        datestring.getHours(),
        datestring.getMinutes(),
        datestring.getSeconds(),
        datestring.getMilliseconds()
      )
    );
  }
}
