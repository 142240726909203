import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RecipeBoDtoNBK } from 'src/app/api/nbk';

@Component({
  selector: 'app-recipe-folder-modal',
  templateUrl: './recipe-folder-modal.component.html',
  styleUrls: ['./recipe-folder-modal.component.scss']
})
export class RecipeFolderModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) {}

  @Output() nameChanged = new EventEmitter();
  @Output() recipeDeleted = new EventEmitter();

  newFolderName: UntypedFormControl;
  recipesInFolder: RecipeBoDtoNBK[];
  folder: {name: string, id: number};
  canChangeName: boolean = false;

  ngOnInit(): void {
    this.newFolderName = new UntypedFormControl(this.folder.name, Validators.required)
  }

  changeName() {
    this.nameChanged.emit(this.newFolderName.value);
    this.toggleChangeName();
  }

  toggleChangeName() {
    this.canChangeName = !this.canChangeName;
  }

  onDeleteRecipe(recipe: RecipeBoDtoNBK) {
    this.recipeDeleted.emit(recipe);
  }

}
