/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { ResultSetUserKeycloakDtoNBK } from '../model/models';
import { UserKeycloakDtoNBK } from '../model/models';
import { UserSettingsDtoNBK } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  protected basePath = 'http://localhost/cloud/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Get the paginated list of users
   * @param $top Max number item to get
   * @param $skip How many record skip before the first item
   * @param $count True to get also the total number of item the server has
   * @param $orderby Order by the result
   * @param $filter Filter the result
   * @param $search String to search in items
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllUsers(
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<ResultSetUserKeycloakDtoNBK>;
  public getAllUsers(
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<ResultSetUserKeycloakDtoNBK>>;
  public getAllUsers(
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<ResultSetUserKeycloakDtoNBK>>;
  public getAllUsers(
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if ($top !== undefined && $top !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$top,
        '$top'
      );
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$skip,
        '$skip'
      );
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$count,
        '$count'
      );
    }
    if ($orderby !== undefined && $orderby !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$orderby,
        '$orderby'
      );
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$filter,
        '$filter'
      );
    }
    if ($search !== undefined && $search !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$search,
        '$search'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<ResultSetUserKeycloakDtoNBK>(
      `${this.configuration.basePath}/bo/user`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get logged user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLoggedUser(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<UserKeycloakDtoNBK>;
  public getLoggedUser(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<UserKeycloakDtoNBK>>;
  public getLoggedUser(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<UserKeycloakDtoNBK>>;
  public getLoggedUser(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<UserKeycloakDtoNBK>(
      `${this.configuration.basePath}/bo/user/getLoggedUser`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get user by id
   * @param id User id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUserById(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<UserKeycloakDtoNBK>;
  public getUserById(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<UserKeycloakDtoNBK>>;
  public getUserById(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<UserKeycloakDtoNBK>>;
  public getUserById(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getUserById.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<UserKeycloakDtoNBK>(
      `${this.configuration.basePath}/bo/user/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * set logged user settings pin
   * @param pin
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setSettingsPin(
    pin: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<UserKeycloakDtoNBK>;
  public setSettingsPin(
    pin: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<UserKeycloakDtoNBK>>;
  public setSettingsPin(
    pin: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<UserKeycloakDtoNBK>>;
  public setSettingsPin(
    pin: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (pin === null || pin === undefined) {
      throw new Error(
        'Required parameter pin was null or undefined when calling setSettingsPin.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: this.encoder });
    }

    if (pin !== undefined) {
      formParams = (formParams.append('pin', <any>pin) as any) || formParams;
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.put<UserKeycloakDtoNBK>(
      `${this.configuration.basePath}/bo/user/pin`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edit current user
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateUser(
    body?: UserKeycloakDtoNBK,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<UserKeycloakDtoNBK>;
  public updateUser(
    body?: UserKeycloakDtoNBK,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<UserKeycloakDtoNBK>>;
  public updateUser(
    body?: UserKeycloakDtoNBK,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<UserKeycloakDtoNBK>>;
  public updateUser(
    body?: UserKeycloakDtoNBK,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.put<UserKeycloakDtoNBK>(
      `${this.configuration.basePath}/bo/user`,
      body,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edit logged user settings
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateUserSettings(
    body?: UserSettingsDtoNBK,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<UserKeycloakDtoNBK>;
  public updateUserSettings(
    body?: UserSettingsDtoNBK,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<UserKeycloakDtoNBK>>;
  public updateUserSettings(
    body?: UserSettingsDtoNBK,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<UserKeycloakDtoNBK>>;
  public updateUserSettings(
    body?: UserSettingsDtoNBK,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.put<UserKeycloakDtoNBK>(
      `${this.configuration.basePath}/bo/user/settings`,
      body,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edit user\&#39;s roles
   * @param id User id
   * @param body roles
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateuserRoles(
    id: string,
    body?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<UserKeycloakDtoNBK>;
  public updateuserRoles(
    id: string,
    body?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<UserKeycloakDtoNBK>>;
  public updateuserRoles(
    id: string,
    body?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<UserKeycloakDtoNBK>>;
  public updateuserRoles(
    id: string,
    body?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateuserRoles.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.put<UserKeycloakDtoNBK>(
      `${this.configuration.basePath}/bo/user/roles/${encodeURIComponent(String(id))}`,
      body,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
