<ngx-datatable
  #appdatatable
  class="material"
  [columns]="_columns"
  [rows]="dataSource.currentItems"
  [limit]="dataSource.limit"
  [offset]="dataSource.page"
  [count]="dataSource.totalItems || 0"
  [externalPaging]="true"
  [externalSorting]="true"
  [scrollbarH]="true"
  columnMode="force"
  (page)="dataSource.onTablePage($event)"
  (sort)="dataSource.onTableSort($event)"
  [selectAllRowsOnPage]="false"
  (select)="elSelected.emit($event)"
  (activate)="onRowActivate($event)"
  [messages]="messages"
  [rowClass]="getRowClass"
  [selected]="selected"
  [groupRowsBy]="groupRowsBy!"
  [selectionType]="selectionType!"
  [ngClass]="className!"
>
  <ng-content></ng-content>
</ngx-datatable>
<ngb-pagination
  *ngIf="!hidePagination"
  [pageSize]="dataSource.limit"
  [page]="dataSource.page + 1"
  (pageChange)="dataSource.setPage($event - 1)"
  [collectionSize]="dataSource.totalItems || 0"
  [maxSize]="5"
></ngb-pagination>

<ng-template #checkboxTpl let-rowIndex="rowIndex" let-row="row">
  <div class="custom-control custom-checkbox">
    <input
      id="select-row-{{ rowIndex }}"
      type="checkbox"
      class="custom-control-input"
      [checked]="_selectionHandler!.isSelected(row)"
    />
    <label
      class="custom-control-label text-hide"
      for="select-row-{{ rowIndex }}"
    ></label>
  </div>
</ng-template>
<!-- (change)="
        _selectionHandler!.toggleItemSelected(
          row,
          $event.target.checked
        )
      " -->
