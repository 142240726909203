import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayBoDtoNBK } from 'src/app/api/nbk';
import { getCookingModeList, getDeviceModelList, SelectableCookingMode, SelectableDeviceModel } from 'src/app/core/utils';

@Component({
  selector: 'app-display-creation-modal',
  templateUrl: './display-creation-modal.component.html',
  styleUrls: ['./display-creation-modal.component.scss']
})
export class DisplayCreationModal implements OnInit {
  deviceModel: SelectableDeviceModel[];
  cookingMode: SelectableCookingMode[];

  @Output() 
  saveDisplay: EventEmitter<DisplayBoDtoNBK> = new EventEmitter();

  createDisplayFormGroup: UntypedFormGroup;

  constructor(public activeModal: NgbActiveModal) {
    this.createDisplayFormGroup = new UntypedFormGroup({
      name: new UntypedFormControl(null,[Validators.required]),
      deviceModel: new UntypedFormControl(null,[Validators.required]),
      cookingMode: new UntypedFormControl(null),
    });
  }

  ngOnInit() {
    this.deviceModel = getDeviceModelList();
    this.createDisplayFormGroup.get('cookingMode')?.disable();
  }

  onDeviceModelChange(e: SelectableDeviceModel) {
    this.cookingMode = getCookingModeList(e.id);
    if ( this.cookingMode && this.cookingMode.length > 0 ) {
      this.createDisplayFormGroup.get('cookingMode')?.enable();
      this.createDisplayFormGroup.get('cookingMode')?.setValidators([Validators.required]);
    }
  }

  onConfirm() {
    if (this.createDisplayFormGroup.invalid) {
      return;
    }

    this.saveDisplay.emit({
      name: this.createDisplayFormGroup.get('name')?.value,
      deviceModel: this.createDisplayFormGroup.get('deviceModel')?.value as DisplayBoDtoNBK.DeviceModelEnum,
      cookingMode: this.createDisplayFormGroup.get('cookingMode')?.value as DisplayBoDtoNBK.CookingModeEnum,
      constructor: false
    });

    this.activeModal.close();
  }

}
