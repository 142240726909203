<div class="modal-header">
  <h2>
    {{ 'MY_DISPLAY.CREATE_DISPLAY' | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3" [formGroup]="createDisplayFormGroup">

  <label class="custom-label">
    {{ 'MY_DISPLAY.FILTER' | translate }}
    <input
      type="text"
      class="custom-input"
      formControlName="name"
      [placeholder]="'MY_DISPLAY.FILTER' | translate" />
    <app-control-errors
      id="validate-name"
      controlName="name"
    ></app-control-errors>
</label>

  <label class="custom-label">
    {{'RECIPE.DEVICE_MODEL' | translate }}
    <ng-select
      [items]="deviceModel"
      (change)="onDeviceModelChange($event)"
      formControlName="deviceModel"
      classList="custom-input"
      bindValue="id"
      bindLabel="name"
      [clearable]="false"
      [searchable]="false"
      [placeholder]="'RECIPE.DEVICE_MODEL' | translate"
      >
      <ng-template ng-option-tmp ng-label-tmp let-item="item">
        {{ item.name | translate }}
      </ng-template>
    </ng-select>
    <app-control-errors
      id="valid-deviceModel"
      controlName="deviceModel"
    ></app-control-errors>
  </label>

  <label class="custom-label" *ngIf="cookingMode && cookingMode.length > 0">
    {{'RECIPE.COOKING_MODE' | translate }}
    <ng-select
      [items]="cookingMode"
      formControlName="cookingMode"
      classList="custom-input"
      bindValue="id"
      bindLabel="name"
      [clearable]="false"
      [searchable]="false"
      [placeholder]="'RECIPE.COOKING_MODE' | translate"
      >
      <ng-template ng-option-tmp ng-label-tmp let-item="item">
        {{ item.name | translate }}
      </ng-template>
    </ng-select>
    <app-control-errors
      id="valid-cookingMode"
      controlName="cookingMode"
    ></app-control-errors>
  </label>

</div>
<div class="modal-footer">
  <button
    [disabled]="!createDisplayFormGroup.valid"
    (click)="onConfirm()"
    class="button-primary"
  >
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</div>
