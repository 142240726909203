/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReducedDeviceDtoNBK } from './reducedDeviceDto';

export interface DisplayListBoDtoNBK {
  id?: number;
  name?: string;
  constructor?: boolean;
  mode?: DisplayListBoDtoNBK.ModeEnum;
  deviceModel?: DisplayListBoDtoNBK.DeviceModelEnum;
  cookingMode?: DisplayListBoDtoNBK.CookingModeEnum;
  lastUpdate?: string;
  devices?: Array<ReducedDeviceDtoNBK>;
}
export namespace DisplayListBoDtoNBK {
  export type ModeEnum = 'SINGLE_DISPLAY' | 'MULTI_DISPLAY';
  export const ModeEnum = {
    SingleDisplay: 'SINGLE_DISPLAY' as ModeEnum,
    MultiDisplay: 'MULTI_DISPLAY' as ModeEnum
  };
  export type DeviceModelEnum = 'ORACLE';
  export const DeviceModelEnum = {
    Oracle: 'ORACLE' as DeviceModelEnum
  };
  export type CookingModeEnum = 'COMBI' | 'HSO';
  export const CookingModeEnum = {
    Combi: 'COMBI' as CookingModeEnum,
    Hso: 'HSO' as CookingModeEnum
  };
}
