/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CoolingDtoNBK } from './coolingDto';
import { MinMaxValueDtoNBK } from './minMaxValueDto';

export interface AttributeDtoNBK {
  vaporType?: AttributeDtoNBK.VaporTypeEnum;
  gilding?: MinMaxValueDtoNBK;
  humidity?: MinMaxValueDtoNBK;
  cookinglevelpin1?: MinMaxValueDtoNBK;
  cookinglevelpin2?: MinMaxValueDtoNBK;
  cooling?: CoolingDtoNBK;
  finish?: MinMaxValueDtoNBK;
}
export namespace AttributeDtoNBK {
  export type VaporTypeEnum = 'ECO' | 'TURBO';
  export const VaporTypeEnum = {
    Eco: 'ECO' as VaporTypeEnum,
    Turbo: 'TURBO' as VaporTypeEnum
  };
}
