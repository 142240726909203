<div
  class="recipe-box recipe-animation"
  [ngClass]="customClass"
  [dndDraggable]="recipe"
  [dndDisableIf]="!draggable"
  (dndStart)="onDragStart($event)"
  (dndEnd)="onDragEnd($event)"
  (dndDrag)="onDrag()"
  [id]="recipe.id"
  [style.backgroundImage]="
    'url(' +
    (recipe.icon && recipe.icon.url ? recipe.icon.url : '/assets/images/recipe_icon_default.png') +
    ')'
  "
>
  <img
    src="assets/images/HCS.png"
    alt="HCS"
    *ngIf="recipe.ics && recipe.cookingMode === 'HSO'"
  />
  <img
    src="assets/images/CCS.png"
    alt="CCS"
    *ngIf="recipe.ics && recipe.cookingMode === 'COMBI'"
  />
  <i
    class="fa fa-times-circle"
    *ngIf="canDelete"
    (click)="deleteRecipe(recipe)"
  ></i>
  <div class="recipe-text-box" [ngClass]="customClass">
    <span #el class="recipe-text" [tooltip]="upperCaseTooltip(recipe)" [isDisabled]="disableTooltip(el)">
      {{ recipe.name }}
    </span>
  </div>
</div>
