import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { DeviceDisplayDtoNBK } from 'src/app/api/nbk';
import { SelectionType } from '@swimlane/ngx-datatable';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-device-selection-modal',
  templateUrl: './device-selection-modal.component.html',
  styleUrls: ['./device-selection-modal.component.scss']
})
export class DeviceSelectionModal implements OnInit {
  @Input() devices: DeviceDisplayDtoNBK[];
  /**
   * contains also the devices for wich now the user is not owner
   * but must be kept for next association
   */
  @Input() associtedDevices: number[];
  @Input() isDefaultDisplay?: boolean;
  @Input() modalTitle: string;

  @Output() 
  saveAssociation: EventEmitter<number[]> = new EventEmitter();

  tableSelectionType: SelectionType;
  devicesDataSource = new ClientFiltrableDataSource<DeviceDisplayDtoNBK>();
  columns: TableColumn[] = [];
  selectedItems: Set<number> = new Set();

  @ViewChild('checkBoxTemplate', { static: true })
  checkBoxTemplate: ElementRef<HTMLElement>;

  @ViewChild('checkBoxHeaderTemplate', { static: true })
  checkBoxHeaderTemplate: ElementRef<HTMLElement>;

  filterDevice: UntypedFormControl = new UntypedFormControl('', []);


  constructor(private ts: TranslateService, public activeModal: NgbActiveModal) { 
    this.tableSelectionType = SelectionType.checkbox;
  }

  ngOnInit() {
    this.columns = [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        width: 30,
        cellTemplate: this.checkBoxTemplate,
        headerTemplate: this.checkBoxHeaderTemplate,
      },
      {
        prop: 'deviceSerial',
        name: this.ts.instant('DEVICES.DEVICE_CARD.SERIAL_NUMBER')
      },
      {
        prop: 'deviceName',
        name: this.ts.instant('DEVICES.DEVICE_CARD.NAME')
      },
      {
        prop: 'deviceModel',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODEL')
      },
      {
        prop: 'displayName',
        name: this.ts.instant('MY_DISPLAY.DISPLAY')
      }
    ];

    this.setDatasource();

    this.filterDevice.valueChanges.pipe(debounceTime(500)).subscribe((v) => {
      this.devicesDataSource.setCustomFilter(
        (item) => 
          item.deviceName?.toLowerCase().includes(v.toLowerCase())! ||
            item.deviceModel?.toLowerCase().includes(v.toLowerCase())! ||
              item.displayName?.toLowerCase().includes(v.toLowerCase())!
      );
      this.devicesDataSource.applyFilters();
    });
  }

  setDatasource() {
    this.devicesDataSource.setItems( this.devices );
    this.devicesDataSource.limit = 7;
    this.associtedDevices.forEach( e => this.selectedItems.add(e) );
  }

  isSelected(row: DeviceDisplayDtoNBK) {
    return this.selectedItems.has(row.deviceId!);
  }

  disableSelection(row: DeviceDisplayDtoNBK) {
    return this.selectedItems.has(row.deviceId!) 
        && this.isDefaultDisplay 
          && this.associtedDevices.includes(row.deviceId!) ;
  }

  toggleSelect(row: DeviceDisplayDtoNBK) {
    if (!this.isSelected(row)) {
      this.selectedItems.add(row.deviceId!);
    } else {
      this.selectedItems.delete(row.deviceId!);
    }
  }

  checboxTooltip(row: DeviceDisplayDtoNBK) {
    return this.disableSelection(row) ? this.ts.instant('MY_DISPLAY.ASSIGN_DEVICE_LOCKED') : "";
  }

  disableSelectAll() {
    return this.areAllSelected() 
        && this.isDefaultDisplay 
          && this.devices.every( d => this.associtedDevices.includes(d.deviceId!) )
  }

  areAllSelected() {
    return this.devices.every( d => this.selectedItems.has(d.deviceId!) );
  }

  onSelectAll(event: any) {
    if (event.target.checked) {
      this.devices.forEach( d => this.selectedItems.add(d.deviceId!) );
    } else {
      if ( this.isDefaultDisplay ) {
        this.selectedItems.clear();
        this.associtedDevices.forEach( e => this.selectedItems.add(e) );
      } else {
        this.devices.forEach( d => this.selectedItems.delete(d.deviceId!) );
      }
    }    
  }

  onConfirm() {
    this.saveAssociation.emit( Array.from( this.selectedItems.values() ) );
    this.activeModal.close();
  }

}
