import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  DatatableComponent as NgxDatatableComponent,
  SelectionType,
  TableColumn
} from '@swimlane/ngx-datatable';
import {
  ClientFiltrableDataSource,
} from 'filtrable-data-source';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, AfterContentChecked {
  @Input() groupRowsBy?: string;
  @Input() hidePagination? = false;
  @Input() disabledLines?: boolean;
  @Input() dataSource: ClientFiltrableDataSource<any>
  _selectionHandler?: SelectedItemsHandler<any>;
  @Input() set selectionHandler(val: SelectedItemsHandler<any>) {
    this._selectionHandler = val;
    this.parseColumns();
  }
  @Input()
  set columns(val: TableColumn[]) {
    this._columns = val;
  }
  
  @Input() highlightedRow: any[] = [];
  @Input() selected: any[] = [];
  @Input() selectionType?: SelectionType = undefined;
  @Output() elSelected = new EventEmitter<any>();
  @Output() elClicked = new EventEmitter<any>();
      
  @ViewChild('checkboxTpl', { static: true }) checkboxTpl: TemplateRef<any>;
  @ViewChild('appdatatable') table: any;
  @Input() messages? = { emptyMessage: this.ts.instant('GLOBAL.NO_DATA') };
  _columns: TableColumn[] = [];

  @Input() className?: string;

  constructor(private ts: TranslateService, private route: ActivatedRoute) {}

  ngAfterContentChecked(): void {
    if ( this.table ) {
      this.table.recalculate();
    }
  }

  async ngOnInit() {
    if (this._selectionHandler) {
      this._selectionHandler.itemsChanged$.subscribe(() => {
        // force ngx-datatable to execute change detection to reflect selection state
        this.selected = [...this.selected];
      });
    }
  }

  getRowClass = (row: any) => {
    return {
      active:  this._selectionHandler && this._selectionHandler.isSelected(row),
      highlight: this.highlightedRow && this.highlightedRow.find( e => e === row ) !== undefined
    }
  };

  private parseColumns() {
    if (this._selectionHandler) {
      this._columns = [
        {
          width: 40,
          sortable: false,
          canAutoResize: false,
          draggable: false,
          resizeable: false,
          cellTemplate: this.checkboxTpl
        },
        ...this._columns
      ];
    }
  }

  onRowActivate($event: any) {
    if ( $event.type === 'click' ) {
      this.elClicked.emit($event.row);
    }
  }

}
