import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RecipeBoDtoNBK } from '../api/nbk';

@Injectable({
  providedIn: 'root'
})
export class MyDisplayService {
  recipeDragged: RecipeBoDtoNBK = {};
  isAlreadyInDisplay: boolean = true;
  goToLastPage: boolean;
  recipeOnDragging: number;
  resetDisplayRecipe: boolean = false;
  resetDisplayFolder: boolean = false;

  reorderRecipe$: Subject<void> = new Subject();

  constructor() {}
}
