<div class="modal-header">
  <h2>
    {{ modalTitle | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="filter-box d-flex justify-content-center">
  <label class="custom-label">
    {{ 'MY_DISPLAY.FILTER' | translate }}/{{ 'DEVICE.INFO.SERIAL' | translate }}/{{ 'MY_DISPLAY.DISPLAY' | translate }}
    <input type="text" class="custom-input" [formControl]="filterDevice" />
  </label>
</div>

<div class="modal-body p-3">
  <app-datatable
    #table
    [dataSource]="devicesDataSource"
    [columns]="columns"
  ></app-datatable>
  
  <ng-template #checkBoxTemplate let-row="row" let-selected="selected" let-value="value">
    <input type="checkbox" 
        [disabled]="disableSelection(row)" 
        [checked]="isSelected(row)" 
        (change)="toggleSelect(row)"
        [tooltip]="checboxTooltip(row)"
    />
  </ng-template>

  <ng-template #checkBoxHeaderTemplate>
    <input type="checkbox" [disabled]="disableSelectAll()" [checked]="areAllSelected()" (change)="onSelectAll($event)">
  </ng-template>

</div>

<div class="modal-footer">
  <button
    (click)="onConfirm()"
    class="button-primary"
  >
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</div>
