/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EventDetailNBK {
  timestamp?: string;
  duration?: string;
  eventType?: EventDetailNBK.EventTypeEnum;
  event?: string;
  camera?: number;
  tempUnit?: string;
  startEvent?: boolean;
}
export namespace EventDetailNBK {
  export type EventTypeEnum =
    | 'SYSTEM'
    | 'RECIPE'
    | 'RECIPE_PHASE'
    | 'RECIPE_PREHEAT'
    | 'RECIPE_HOLD'
    | 'SAMPLE'
    | 'ALARM'
    | 'DOOR';
  export const EventTypeEnum = {
    System: 'SYSTEM' as EventTypeEnum,
    Recipe: 'RECIPE' as EventTypeEnum,
    RecipePhase: 'RECIPE_PHASE' as EventTypeEnum,
    RecipePreheat: 'RECIPE_PREHEAT' as EventTypeEnum,
    RecipeHold: 'RECIPE_HOLD' as EventTypeEnum,
    Sample: 'SAMPLE' as EventTypeEnum,
    Alarm: 'ALARM' as EventTypeEnum,
    Door: 'DOOR' as EventTypeEnum
  };
}
