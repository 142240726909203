/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PreheatDtoNBK } from './preheatDto';

export interface RecipeDtoNBK {
  id?: number;
  idUnique?: string;
  status?: RecipeDtoNBK.StatusEnum;
  creationLanguage?: string;
  version?: number;
  ics?: boolean;
  editable?: boolean;
  user?: string;
  lastUpdate?: string;
  country?: string;
  preheat?: PreheatDtoNBK;
  cost?: number;
  deviceModel?: RecipeDtoNBK.DeviceModelEnum;
  kcal?: number;
  pieces?: number;
  time?: number;
  cookingMode?: RecipeDtoNBK.CookingModeEnum;
  typeId?: number;
  dirtLevel?: number;
}
export namespace RecipeDtoNBK {
  export type StatusEnum = 'ACTIVE' | 'DELETED';
  export const StatusEnum = {
    Active: 'ACTIVE' as StatusEnum,
    Deleted: 'DELETED' as StatusEnum
  };
  export type DeviceModelEnum = 'ORACLE';
  export const DeviceModelEnum = {
    Oracle: 'ORACLE' as DeviceModelEnum
  };
  export type CookingModeEnum = 'COMBI' | 'HSO';
  export const CookingModeEnum = {
    Combi: 'COMBI' as CookingModeEnum,
    Hso: 'HSO' as CookingModeEnum
  };
}
